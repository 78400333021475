<template>
    <main class="main-container">
        <div class="wrapper">
            <div class="text-element text-center">
                <h1>HiddenCord</h1>
                <h3>Your discord music bot with top audio quality!</h3>
                <div class="flex items-center justify-center mb-6">
                    <a class="button">
                        <i class="fab fa-discord"></i><span>Invite</span>
                    </a>
                </div>
            </div>

            <div class="text-element">
                <h1 class="text-center">Changelogs</h1>

                <div class="changelog">
                    <h3>25-10-2021</h3>
                    <ul>
                        <li>Today is the day i finally decided to publish my the beta of my bot bot after weeks of private use and testing!</li>
                        <li>Future updates will contain things like spotify support, better youtube support and much more</li>
                    </ul>
                </div>
            </div>
        </div>
    </main>
</template>

<style>

.changelog {
    @apply p-4 border-4 border-indigo-700 rounded-2xl bg-gray-800;
}

.changelog + .changelog {
    @apply mt-5;
}

.changelog ul {
    @apply list-disc;
}

.changelog li {
    @apply ml-6;
}

</style>